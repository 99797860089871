html, body {
    ::-webkit-scrollbar {
        display: none;
        width: 1px;
    }

    ::-webkit-scrollbar-track {
        display: none;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    scroll-behavior: smooth;
}

&::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
}

&::-webkit-scrollbar-thumb:horizontal {
    display: none;
}
